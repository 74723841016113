import ArrowIcon from 'components/icons/ArrowIcon';
import PropTypes from 'prop-types';
import ThemeButton from 'components/buttons/ThemeButton';
import { matchesMedia } from '@activebrands/core-web/utils/responsive';
import media from '@activebrands/core-web/config/media';

const elementStyles = {
    position: 'absolute',
    top: '50%',
    transformY: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    padding: 0,
    width: ['30px', null, null, null, null, '40px'],
    height: ['30px', null, null, null, null, '40px'],

    [media.max['desktop.sm']]: {
        // This is custom for mobile to fix issue with freezing button in hover state when clicked
        ':hover:not(:disabled):not(.disabled)': {
            backgroundColor: 'var(--color-bg-button-defaultinverted)',
            color: 'var(--color-text-button-defaultinverted)',
            borderColor: 'var(--color-border-button-defaultinverted)',
        },
        ':active:not(:disabled):not(.disabled)': {
            backgroundColor: 'var(--color-bg-button-defaultinverted-hover)',
            color: 'var(--color-text-button-defaultinverted-hover)',
        },
    },
};

const MediaNavigation = ({ mediaItems = [], slider = () => null }) => {
    const isDesktop = matchesMedia(media.min['desktop.sm']);
    const showNavigationButtons = isDesktop ? mediaItems.length > 2 : mediaItems.length > 1;

    if (!showNavigationButtons) {
        return null;
    }

    return (
        <>
            <ThemeButton $style={{
                left: ['8px', null, null, null, null, '16px'],
                ...elementStyles
            }}
                theme="defaultinverted"
                onClick={() => slider.slideToPrev()}>
                <ArrowIcon $style={{ transform: 'rotate(180deg)' }} size={['12px', null, null, null, null, '16px']} />
            </ThemeButton>
            <ThemeButton $style={{
                right: ['8px', null, null, null, null, '16px'],
                ...elementStyles
            }}
                theme="defaultinverted"
                onClick={() => slider.slideToNext()}>
                <ArrowIcon size={['12px', null, null, null, null, '16px']} />
            </ThemeButton>
        </>
    );
};

MediaNavigation.propTypes = {
    mediaItems: PropTypes.array,
    slider: PropTypes.func,
};

export default MediaNavigation;
