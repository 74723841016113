import PropTypes from 'prop-types';
import ThumbnailContent from 'templates/ProductPage/MediaGallery/ThumbnailContent';
import media from '@activebrands/core-web/config/media';
import { styled } from '@activebrands/core-web/libs/styletron';
import useSlider from '@activebrands/core-web/libs/slider/useSlider';
import fm from 'format-message';

const Wrapper = styled('div', {
    position: 'absolute',
    bottom: '0px',
    width: '100%',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'space-between',

    [media.min['desktop.sm']]: {
        bottom: '12px',
        justifyContent: 'center',
    },
});

const ThumbnailButton = styled('button', ({ $isActive }) => ({
    flex: 1,
    backgroundColor: $isActive ? 'var(--color-bg-swipe-indicator-active)' : 'var(--color-bg-swipe-indicator)',
    margin: '0 2px',
    transition: 'background-color 300ms var(--ease)',

    [media.min['desktop.sm']]: {
        flex: 'none',
        backgroundColor: 'transparent',
        margin: '0',
    },
}));

const SwipeIndicator = styled('div', {
    height: '2px',

    [media.min['desktop.sm']]: {
        display: 'none',
    },
});

const MediaThumbnail = styled('div', ({ $isFirstActive, $isSecondActive }) => ({
    display: 'none',

    [media.min['desktop.sm']]: {
        display: 'block',
        height: "60px",
        overflow: 'hidden',
        boxSizing: 'border-box',
        transition: 'all 300ms var(--ease)',
        marginRight: $isFirstActive ? '-1px' : '4px',
        marginLeft: $isSecondActive ? '-1px' : '4px',
        boxShadow:
            $isFirstActive || $isSecondActive
                ? '0 0 0 1px var(--color-border-thumbnail)'
                : '0 0 0 1px var(--color-border-thumbnail-active)',
    },
}));

const Thumbnails = ({ $style = {}, items = [], slider = {} }) => {
    const { index } = useSlider(slider, ['index', 'isInfinite', 'numberOfSlides', 'slidesPerView']);

    if (items.length < 2) {
        return null;
    }

    return (
        <Wrapper style={{ ...$style }}>
            {items.map(({ url, type, typeValue }, i) => {
                const isTypeValueProduct = typeValue?.toLowerCase() === 'product';
                const isSecondActive = index + 1 === i;
                const isFirstActive = index === i;

                return (
                    <ThumbnailButton
                        key={url}
                        type="button"
                        aria-label={fm(`Navigate to Thumbnail ${i}`)}
                        $isActive={isFirstActive}
                        onClick={() => slider.slideTo(i, true)}
                    >
                        <SwipeIndicator $isActive={isFirstActive} />
                        <MediaThumbnail $isFirstActive={isFirstActive} $isSecondActive={isSecondActive}>
                            <ThumbnailContent
                                type={type}
                                url={url}
                                isTypeValueProduct={isTypeValueProduct}
                                loading="lazy"
                            />
                        </MediaThumbnail>
                    </ThumbnailButton>
                );
            })}
        </Wrapper>
    );
};

ThumbnailContent.propTypes = {
    type: PropTypes.string.isRequired,
    typeValueProductStyles: PropTypes.object,
    url: PropTypes.string.isRequired,
};

Thumbnails.propTypes = {
    $style: PropTypes.object,
    items: PropTypes.array.isRequired,
    slider: PropTypes.object.isRequired,
};

export default Thumbnails;
